import React from "react";
import { Container } from "react-bootstrap";
export default function AboutUs() {
  return (
    <Container style={{ backgroundColor: "#FAFAFA" }}>
      <h3> About Us</h3>
      Conveniently located in the heart of Tsim Sha Tsui, Majestic 7 Guesthouse
      (formerly KAT Hotel) offers accommodation in one of Hong Kong's most
      vibrant neighborhoods. We are within a two-minute walk from the MTR Tsim
      Sha Tsui station, and within ten minutes walk from the Star Ferry and
      Kowloon Park, as well as near many shopping districts, night markets, food
      vendors, and museums. We are a family-run guesthouse and have native
      English, Cantonese and Tagalog speakers. Our affordable air-conditioned
      rooms are quietly situated on the seventh floor of an apartment building
      and each room has its own private, attached bathroom. We live on the same
      floor & monitor the guesthouse with security surveillance cameras. It is
      the perfect base for an adventurous traveller!
    </Container>
  );
}
