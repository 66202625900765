import axios from "axios";
import { returnErrors } from "./errorActions";
import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL
} from "./authTypes";

//check token & load user
export const loadUser = () => (dispatch, getState) => {
  console.log("in loadUser... ");
  // user loading
  dispatch({
    type: USER_LOADING
  });

  // get token from local storage
  const token = getState().auth.token;

  //Headers
  const config = {
    headers: {
      "Content-type": "application/json"
    }
  };

  //if token add to headers
  if (token) {
    config.headers["x-auth-token"] = token;
  }

  //https://m7api-js.herokuapp.com

  axios
    //    .get("/api/auth/user", config)
    .get("https://m7api-js.herokuapp.com/api/auth/user", tokenConfig(getState))

    .then(res =>
      dispatch({
        type: USER_LOADED,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch(returnErrors(err.response.data, err.response.status));
      dispatch({
        type: AUTH_ERROR
      });
    });
};

//Register User
export const register = ({ name, email, password }) => dispatch => {
  //Headers
  const config = {
    headers: {
      "Content-type": "application/json"
    }
  };
  //Request body
  const body = JSON.stringify({ name, email, password });

  axios
    .post("https://m7api-js.herokuapp.com/api/users", body, config)
    .then(res =>
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch(
        returnErrors(err.response.data, err.response.status, "REGISTER_FAIL")
      );
      dispatch({ type: REGISTER_FAIL });
    });
};

//Login user
export const login = ({ email, password }) => dispatch => {
  //Headers
  const config = {
    headers: {
      "Content-type": "application/json"
    }
  };
  //Request body
  const body = JSON.stringify({ email, password });

  axios
    .post("https://m7api-js.herokuapp.com/api/auth", body, config)
    .then(res =>
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      })
    )
    .catch(err => {
      dispatch(
        returnErrors(err.response.data, err.response.status, "LOGIN_FAIL")
      );
      dispatch({ type: LOGIN_FAIL });
    });
};

//Logout user
export const logout = () => {
  return {
    type: LOGOUT_SUCCESS
  };
};

///setup config headers & token
export const tokenConfig = getState => {
  // get token from local storage
  const token = getState().auth.token;

  //Headers
  const config = {
    headers: {
      "Content-type": "application/json"
    }
  };

  //if token add to headers
  if (token) {
    config.headers["x-auth-token"] = token;
  }
  return config;
};
